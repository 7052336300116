<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송목적</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발송목적<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Radio
              :dataList="'alarm_test_register' | getSelectDataList"
              :value.sync="viewModel.model.isTest"/>
            <p class="desc_info">💡 테스트 발송시 테스터로 등록된 유저에게만 발송됩니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">타겟설정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>광고유무<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Radio
              :dataList="'alarm_ad_register' | getSelectDataList"
              :value.sync="viewModel.model.isAd"/>
          </td>
        </tr>
        <tr>
          <th>플랫폼<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Selectbox
              :dataList="'alarm_platform' | getSelectDataList"
              :value.sync="viewModel.model.platform"
              maxWidth="300px" />
          </td>
        </tr>
        <tr>
          <th>회원구분<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Selectbox
              :dataList="'alarm_audience' | getSelectDataList"
              :value.sync="viewModel.model.audience"
              maxWidth="300px" />
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">레몬트리 앱내 알림</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>알림 타이틀<IconSvg iconName="essential" :size="8" iconColor="#F04848" /><span class="txt_info">(채널 구분)</span></th>
          <td>
            <Selectbox
              :dataList="'alarm_chanel' | getSelectDataList"
              :value.sync="viewModel.model.chanel"
              maxWidth="300px" />
          </td>
        </tr>
        <tr>
          <th>알림 본문<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="알림 본문을 입력해주세요"
              :value.sync="viewModel.model.content">
              {{ viewModel.model.isAd ? '(광고)' : '' }}
            </Input>
          </td>
        </tr>
        <tr>
          <th>랜딩 URL</th>
          <td>
            <Input
              placeholder="랜딩 URL을 입력해주세요"
              :value.sync="viewModel.model.landingUrl"/>
            <p class="desc_info">💡 별도의 URL이 지정되지 않은 경우, 알림에 링크 버튼이 노출되지 않습니다.</p>
          </td>
        </tr>
        <tr>
          <th>랜딩 버튼명<IconSvg v-if="viewModel.model.landingUrl" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="랜딩 버튼명을 입력해주세요"
              :value.sync="viewModel.model.landingBtnText"/>
            <p class="desc_info">💡 랜딩 URL을 지정하신 경우, 버튼명도 지정해야 합니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">디바이스 푸시</strong>
        </div>
        <div class="area_right">
          <SwitchCheck
            :value.sync="viewModel.model.isPush"
            :isSwitchLeft="false"
            text="푸시 발송" />
          <SwitchCheck
            :value.sync="viewModel.model.isSameAppAlarm"
            :isDisabled="!viewModel.model.isPush"
            :isSwitchLeft="false"
            text="앱 내 알림과 동일한 내용 적용" />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>푸시 타이틀</th>
          <td>
            <Input
              placeholder="푸시 타이틀을 입력해주세요"
              :isDisabled="!viewModel.model.isPush || viewModel.model.isSameAppAlarm"
              :value.sync="pushTitle"/>
          </td>
        </tr>
        <tr>
          <th>푸시 본문</th>
          <td>
            <Input
              placeholder="푸시 본문을 입력해주세요"
              :isDisabled="!viewModel.model.isPush || viewModel.model.isSameAppAlarm"
              :value.sync="pushContent">
              {{ viewModel.model.isAd ? '(광고)' : '' }}
            </Input>
          </td>
        </tr>
        <tr>
          <th>푸시 랜딩</th>
          <td>
            <p class="desc_info">💡 사용자가 푸시를 터치하면 앱내 알림에서 지정한 경로로 이동합니다. 지정한 경로가 없는 경우 레몬트리 앱이 실행됩니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송일정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>예약 일시<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <DateTimePicker
              :value.sync="viewModel.model.send_at"
              :minHour="viewModel.model.isAd ? 8 : null"
              :maxHour="viewModel.model.isAd ? 21 : null"/>
            <p v-if="viewModel.model.isAd" class="desc_info">💡 광고 메세지는 오전 8시부터 오후 9시까지만 보낼 수 있습니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="APP_ALARM_LIST"
          :routeQuery="viewModel.model.tabStatus ? { status : viewModel.model.tabStatus } : {}"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="등록"
          @onClickBtn="viewModel.onClickComplete()"/>
        <Button
          v-if="viewModel.isModify"
          btnSize="medium"
          btnStyle="primary_border"
          text="내용복제"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { convertIdToText, getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
// viewModel
import AlarmDetailViewModel from '@/views/app/alarm/viewModel/AlarmDetailViewModel'

export default {
  name:'AlarmRegister',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    Radio,
    Selectbox,
    Input,
    SwitchCheck,
    DateTimePicker,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    const status = this.$route.params.status;
    this.viewModel.init(id,status);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new AlarmDetailViewModel(),
    }
  },
  computed:{
    pushTitle(){
      const chanelText = convertIdToText(this.viewModel.model.chanel , getSelectDataList('alarm_chanel'))
      return this.viewModel.model.isSameAppAlarm ? chanelText : this.viewModel.model.pushTitle
    },
    pushContent(){
      return this.viewModel.model.isSameAppAlarm ? this.viewModel.model.content : this.viewModel.model.pushContent
    }
  }
}
</script>